import { useTranslation } from 'react-i18next';
import './Banner.scss';

function Banner() {
  const { t } = useTranslation();

  return (
    <div className='banner' data-animation>
      <div className='container'>
        <div className='banner__block'>
          <h1 className='banner__title'>
            {t('BykovArtem')}
            <br />
            <p className='banner__cybertitle'>
              <span data-typed-animation>Frontend developer</span>
              <span className='banner__title-small' data-typed-animation>
                JS | TS | React
              </span>
            </p>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
